@import "../../mixins";

.bottom-sheet-container {
  bottom: 0;
  z-index: 10000;
  background: #fff;
  //box-shadow: 0 1px 20px rgba(0, 0, 0, 0.80);
  max-height: 0;
  overflow: hidden;
  pointer-events: all;
  width: 100%;
  max-width: $max-width;
  height: 100%;
  left: 0;
  right: 0;
  bottom:0;
  position: absolute;
  transform: transform(0,-100%);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
   -ms-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
       transition: all 0.7s ease-out;

  .bottom-sheet-main-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .CarouselItem{
      overflow: hidden;
      background-color: #222222;
      height:216px;
      overflow:hidden;
      width:100%;
      img.carousel-image {
        width: 100%;
        height: 216px;
        display: block;
        margin: 0 auto;
        object-fit: cover;
      }
    }

    .header-row {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 10;
      padding: 10px;
      .close-button {
        background: #000;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.80);
        width: 34px;
        height: 34px;
        border-radius: 34px;
        min-width: auto;
        user-select: none;
        outline: none;
        &:focus {
          user-select: none;
          outline: none;
        }
      }
    }
  }

  &.open{
    transform: transform(0,0%);
    max-height: 90vh;
  }

  &.bottom-sheet-detail.open{
    max-height: 100vh;
  }
  
}
