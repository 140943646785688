.screen-wait-for-approvement {

  .container-loader{
    width:100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin-top:60px;
  }

  .message{
    font-family: 'Nunito';
    font-size: 18px;
  }

  .error_message{
    margin-top:40px;
  }
  
  .container-img-loader{
    margin-top:24px;
    height: 180px;
    width: 180px;
    overflow: hidden;
    padding:18px;
    position: relative;
    margin: 0 auto;
    
    .bkg-img{
        background:rgb(229,229,229);
        border-radius:50%;
        height: 100%;
        width: 100%;
     }

    .loader-img{
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      height:100%;
      z-index: 2;
    }
  }


}
