@import "../../variables";


.cart-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  
  .cart-badge {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: $color-accent;
    position: absolute;
    top: 10px;
    left: 50%;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
  }
  .cart-icon {
    width: 24px;
    height: 24px;
  }
}

.restaurant-header {
  margin-bottom: 20px;
  .back-button {
    color: #000;
    padding: 12px 0;
    user-select: none;
    outline: none;
    &:focus {
      user-select: none;
      outline: none;
    }
  }

  .title{
    overflow: hidden;
    img{
      width:100%;
      max-width: 240px;
    }
  }
  
  .cart-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    
    .cart-badge {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background: $color-accent;
      position: absolute;
      top: 0;
      left: calc(50% - 5px);
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      line-height: 25px;
    }
    .cart-icon {
      width: 20px;
      height: 20px;
    }
  }
  &.full-width {
    img {
      max-width: 250px;
      max-height: none;
    }
  }
}

.delivery-mode-label {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
