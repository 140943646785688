@import "../../variables";

.screens.menu-cohose-screeen{
 
  .content_container{
    height: calc(100vh - 200px);
  }

  .menu-chooser-item {
    border-radius: 50px;
    padding: 20px;
    background: $color-accent;
    color: $color-white;
    text-transform: uppercase;
  }

}


.container.page-container.menu-chooser-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-left: 37px;
  padding-right: 37px;
  .menu-chooser-header {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    color: $color-black;
    font-size: 24px;
    text-align: center;
  }

  .menu-chooser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    .menu-chooser-item {
      border-radius: 50px;
      padding: 20px;
      background: $color-accent;
      color: $color-white;
      text-transform: uppercase;
    }
  }
}
