@import "../../variables";

.payment_screen {

  .label_total_payment{
    margin-top:16px;
    margin-bottom:16px;
  }

  .label_form_payment{
    font-size: 14px;
    color: #222;
    font-weight: bold;
  }

  .card-element, .card-number-element, .card-expiry-element, .card-cvc-element {
    min-height: 44px;
    border: 1px solid $color-gray;
    line-height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 5px;
  }
  .total-order-price, .total-order-price-label {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 18px;
  }

  .container_buttons_action{
    margin-top:44px;
  }

}
