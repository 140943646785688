@import "../../variables";

.availability-box {
  background: #fff;
  box-shadow: 0 1px 10px #bbb;
  border-radius: 10px;
  display: inline-grid;
  padding: 10px;
  margin-left: 7px;
  cursor: pointer;

  &.not-available {
    pointer-events: none;
  }

  &.selected {
    border: 3px solid $color-accent;
  }

  .availability-day {
    font-size: 12px;
    text-transform: capitalize;
  }

  .availability-hours {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
  }

  .not-available-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    padding: 10px 15px;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-end;
    p {
      font-family: 'Nunito', sans-serif;
      font-weight: bold;
      font-size: 10px;
      margin-bottom: 0;
    }
  }
}
