@import "../../variables";

.screen.order_recap_screen{
  padding-bottom: 240px;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 500;
  overflow-y: scroll;

  

  .recap-header, .MuiTypography-root.recap-header {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    text-align: left;
  }
  .payment_buttons_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .payment-method-button {
    max-width: 180px;
    width: 100%;
    height: 50px;
    border: 2.5px solid #ddd;
    line-height: 50px;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    &.selected {
      border: 2.5px solid $color-accent;
    }
    &.disabled {
      cursor: none;
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .delivery-details-label {
    font-size: 14px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
    text-align: left;
  }
  .availabilities-container{
    overflow-x: scroll;
    white-space: nowrap;
    margin-bottom:20px;
    .row{
         display:block; 
        .availability-box:first-child {
              margin-left: 0 !important;
        }
    }
    
  }

  .dits-menu-item-row {
    padding: 10px;
  }
  .recap-item-row {
    padding: 10px 15px;
    margin-left: 0;
    margin-right: 0;
    div[class*="col"] {
      padding: 5px;
    }
    .recap-item-name-label {
      font-family: 'Nunito', sans-serif;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
    }
    .recap-item-price-label {
      font-family: 'Nunito', sans-serif;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
    }
  }
}
