.screen-loder {
    background:rgb(241,241,241);
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    overflow: hidden;
    z-index: 10;

    &.overlay{
        background:rgba(0,0,0,0.6);
    }

    .spinner{
        color: "#fe3436";
        width: 50px;
        height: 50px;
    }

    .screen-container-loader{
      background:#fff;
      max-width: 576px;
      margin: 0 auto;
      width:100%;
      display: flex;
      align-items: center;
      align-content: center;
      flex-direction: column;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      .message{
        font-family: 'Nunito';
        font-size: 18px;
      }
      .container-img-loader{
        margin-top:24px;
        height: 180px;
        width: 180px;
        overflow: hidden;
        padding:18px;
        position: relative;
        margin: 0 auto;

        .bkg-img{
            background:rgb(229,229,229);
            border-radius:50%;
            height: 100%;
            width: 100%;
         }

        .loader-img{
          position: absolute;
          top:0;
          left: 0;
          right: 0;
          bottom: 0;
          height:100%;
          z-index: 2;
        }
      }
      
    }
}