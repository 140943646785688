@import "../../variables";


.screen.restaurant-screen{

  .header{
    height:84px;
    width:100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    
    .logo{
      max-width: 230px;
      min-height: unset;
      max-height: unset;
    }

  }

  .content_container{
    height:calc(100% - 144px); //54px + 46 px 100px
  }
  
  .footer_container{
      height:90px;
    .logo{
      max-height: 24px;
      max-width: 100%;
      margin:0 auto;
    }
  }
}





.container.page-container.restaurant-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-left: 37px;
  padding-right: 37px;
  overflow-y: scroll;
  .link-chooser-header {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    color: $color-black;
    font-size: 24px;
    text-align: center;
  }
  .link-chooser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    .link-chooser-item {
      border-radius: 50px;
      padding: 20px;
      background: $color-accent;
      color: $color-white;
      text-transform: uppercase;
    }
  }
}
