@import "./variables";
@import "./mixins";
@import "./mediaprint";

.app {
  text-align: center;
  max-width: $max-width;
  display: block;
  margin: 0 auto;
  background: #ffffff;
  height: 100vh !important;
  overflow-y: hidden;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

.bottom-fixed-row {
  position: fixed;
  box-shadow: 0 1px 10px #bbb;
  width: 100%;
  bottom: 0;
  z-index: 10;
  max-width: $max-width;
  left: 50%;
  transform: translate(-50%);
  padding: 24px 8px;
}

.accent-button {
  border-radius: 50px !important;
  padding: 10px 20px !important;
  background: #fe3436 !important;
  color: $color-white;
  user-select: none;
  outline: none;

  &:focus {
    user-select: none;
    outline: none;
  }

  &.Mui-disabled {

  }

  &.full-width {
    width: 100%;
  }

  .MuiButton-label {
    color: $color-white;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 700;
    @include sm {
      font-size: 11px !important;
    }
  }

  &.MuiButton-root.Mui-disabled {
    background: $color-gray !important;
  }
}

.label_field{

}

.container-buttons-action{
  margin:0 auto;
  margin-top:80px;
  max-width: 300px;
  width:100%;
  overflow: hidden;
  .button{
    width:100%;
    height:48px;
    margin-top:12px;
    &.first{
      margin-top:0px;
    }
  }
}

.container.page-container {
  padding: 36px 14px;
  height: 100% !important;

  .page-header, .MuiTypography-root.page-header {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif;
  }

  .MuiTypography-h1, .MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6 {
    font-family: 'Nunito', sans-serif;
  }
}

.form-row {
  margin-top: 22px !important;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.screen {
  background:rgb(241,241,241);
  position: relative;
  overflow: hidden;
  z-index: 1;
  widows: 100vw;
  height: 100vh;
  background: #fff;  
  overflow: hidden;

  .scroll{
    overflow-y: scroll;
  }
  

  .header{
    height:54px;
    width:100%;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow:hidden;

    .row{
      line-height: 54px;
    }

    &.block{
      display:block;
      margin-top: 10px;
    }

    .logo{
      max-height: 28px;
      max-width: 100%;
    }

  }
  .subheader{
     width:100%;
  }

  .content_container{
    overflow-y: auto;
    overflow-x:hidden;
    width: 100%;
    height:calc(100vh - 54px); //54px + 46 px 100px
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;

    &.with_footer{
      height:calc(100% - 140px);
    }

  }

  .footer_container{
    position: absolute;
    left:0px;
    bottom: 0px;
    height:60px;
    width:100%;
    
    .logo{
      max-height: 24px;
      max-width: 100%;
      margin:0 auto;
    }
    .collaboration-with{
      margin-bottom: 4px;
      font-size: 12px;
      text-align: center;
    }

  }
}

.user_questions{
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  margin-bottom: 0px;
}

.container_choose_buttons{
  margin:0 auto;
  margin-top:20px;
}
