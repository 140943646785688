@import "../../variables";

.menu-item-row {
  padding: 10px 15px;
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid #ddd;

  div[class*="col"] {
    padding: 5px;
  }

  .menu-item-qty-label {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 700;
  }

  .menu-item-name-label {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    display: block;
  }

  .menu-item-addons-label {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-style: italic;
    color: $color-accent;
    display: block;
  }

  .menu-item-minus-label, .menu-item-notes-label {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-style: italic;
    color: #000;
    display: block;
  }

  .menu-item-price-label {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 600;
  }

  .delete-action-button {
    background: #ededed !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 20px !important;
    margin-left: 10px;
    min-width: auto !important;
    user-select: none;
    outline: none;
    &:focus {
      user-select: none;
      outline: none;
    }
  }
}
