@import "../../variables";
.footer {
  padding-bottom: 36px;
  .collaboration-with {
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
    color: $color-gray;
  }
  img.logo {
    width: 100%;
    max-width: 140px;
  }
}
