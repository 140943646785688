@import "../../variables";

.container.page-container.signin-container {
  padding-bottom: 60px;
  height: 100%;
  overflow: hidden;
  .signin-container-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    z-index: 2;
    img.signin-background {
      width: 100%;
      height: 100px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
    .row.signin-logo-container {
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      img.signin-logo {
        max-width: 100%;
        max-height: 100px;
        object-fit: contain;
      }
    }
  }
  .signin-inner-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    padding-top: 50px;
    overflow-x: hidden;
    .privacy-policy-container {
      padding: 0px 40px;
    }

    .profile-form-container {
      padding: 20px 40px;
    }

    .signin-bottom-fixed-row {
      background: #fff !important;
    }

    .privacy-policy-row {
      text-align: center;

      .privacy-policy-link {
        color: $color-accent;
        font-weight: 600;
      }
    }
  }
}
