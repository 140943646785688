@import "../../variables";

.bottom-fixed-row.ask-delete-item-row {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
  z-index: 100;
  .ask-delete-item-label {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .allow-button {
    background: $color-accent;
    width:50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 10px;
  }
  .deny-button {
    background: $color-gray;
    width:50px;
    height: 50px;
    border-radius: 50px;
  }
}
