.popupOffline{
    position: fixed;
    top:0px;
    left:0px;
    right: 0px;
    height:120px;
    z-index:999;
    background-color: transparent;
    color:white;
    font-size: 16px;
    font-family: 'Nunito';
    display: block;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
    -webkit-transform: translate(0,-220px);
    -moz-transform: translate(0,-220px);
    -o-transform: translate(0,-220px);
    -ms-transform: translate(0,-220px);
    transform: translate(0,-220px);
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;

    &.show{
        -webkit-transform: translate(0,0px);
        -moz-transform: translate(0,0px);
        -o-transform: translate(0,0px);
        -ms-transform: translate(0,0px);
        transform: translate(0,0px);
    }

    .container-message{
        background-color: #fe3436;
        text-align: center;
        max-width:576px;
        height: 100%;
        margin:0 auto;
        text-align: center;
        display: flex;
        align-items: center;
        align-content: center;
        justify-items: center;
        overflow: hidden;
        font-size: 16px;
        font-family: "Nunito";
        .message{
            font-size: 16px;
            font-family: "Nunito","Optima";
        }
    }
   
}