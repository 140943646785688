@import "variables";
// Small devices
@mixin sm {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin all-smartphones {
  @media only screen and (max-height: 1024px) and (orientation: portrait) {
    @content;
  }
}

/**
 * iPad with landscape orientation.
 */
@mixin ipad-landscape {
  @media only screen and (max-height: 1024px) and (orientation: portrait) {
    @content;
  }
}

/**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
@mixin iphone-5 {
  @media only screen and (device-aspect-ratio: 40/71) {
    @content;
  }
}
