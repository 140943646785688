.MuiDialog-container.error-dialog {
  .dialog-title {
    text-transform: uppercase !important;
  }
  .close-button {
    user-select: none;
    outline: none;
    &:focus {
      user-select: none;
      outline: none;
    }
  }
}
