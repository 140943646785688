@import "../../variables";

.screen.thanks_screen {

  .content_container_thanks_order{
    max-width: 450px;
    width:100%;
    margin:0 auto;
    justify-content: start;

    .container_code_order{
      margin-top:35px;
      background:#EBEBEB;
      padding:22px;
      font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        /* or 160% */
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #000000;

      .title{
        font-weight: bold;
        font-size: 24px;
      }
      .subtitle{
        font-style: normal;
        font-size: 14px;
        line-height: 1.2;
      }
      .code{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 42px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2px;
        color: #000000;
        margin-top:16px;
        margin-bottom:16px;
      }
    }

    .container_description_order{
      margin-top:22px;
    }
    .container_mail_order{
      margin-top:8px;
    }


  }

  .container-data{
    padding: 16px;
    overflow: hidden;
  }

  .label_text{
    font-size: 14px;
  }

  .page-sub-header, .MuiTypography-root.page-sub-header {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    font-weight: bold;
  }
  .email-address-label {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: $color-accent;
    text-align: center;
  }
  .dits-code-label {
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
}
