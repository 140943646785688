$color-white: #ffffff;
$color-black: #000000;
$color-accent: #fe3436;
$color-grewish-brown: #454545;
$color-green: #96e272;
$color-gray: #888888;


$max-width: 576px;
$max-width-padded-fixed: 544px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1200px;
