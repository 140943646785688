@import "../../variables";

/*
body.scroll{
  .screen.menu-quid-screen{
    .container_header{
        height:34px;
        overflow:hidden;
    }
  }    
}
*/

.screen.menu-quid-screen {
  .container_header {
    top: 0px;
    left: 0px;
    right: 0px;
    background: #fff;
    z-index: 20;
    max-width: 576px;
    margin: 0 auto;
  }

  .header {
    display: block;
    height: 54px;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .MuiTabs-scroller {
    -webkit-transition-property: -webkit-scrolling;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-delay: 0.1s;
    -webkit-scroll-behavior-duration: 0.5s;
    -webkit-scroll-behavior-timing-function: ease-in;
  }

  .row-scroll {
    margin: 0;
  }

  .scrollmenu {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 auto;

    .scrollmenu-item {
      display: inline-block;
      color: #000;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      font-family: Nunito;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.2px;
      padding: 4px 4px;
      outline: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-right: 16px;
      cursor: pointer;
      a {
        text-decoration: none;
        cursor: pointer;
        color: #000;
        font-size: 18px;
        font-weight: 300;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
      }

      &.selected {
        color: #fe3436;
        a {
          font-weight: bold;
          font-size: 20px;
          color: #fe3436;
        }
      }
    }
  }

  .menulist {
    padding-bottom: 50vh;
  }

  .section-list-items {
    padding-left: 8px;
    padding-right: 8px;
  }

  .section-title {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    margin: 15px 0 15px;
    padding-top: 15px;
    text-align: center;
    text-transform: uppercase;
  }

  .section-submenu-title {
    color: #525252;
    font-weight: 600;
    font-size: 16px;
    display: block;
    line-height: 30px;
    text-align: left;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    background: #fff;
    top: 0px;
    z-index: 10;
  }
  .section-description {
    font-size: 14px;
    font-weight: 300;
    margin: 15px 0;
    color: #888;
    text-align: left;
  }
  .menu-item-card {
    padding-left: 8px;
    padding-right: 8px;
  }

  .MuiTabs-root.tabbar {
    width: 100%;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    padding-left: 16px;
    margin-top: 22px;

    .menu-tab {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.2px;
      padding: 4px 4px;
      outline: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        user-select: none;
        outline: none;
      }
      &:first {
        padding-left: 0px;
      }

      &.Mui-selected {
        color: $color-accent;
        opacity: 1;
      }
    }
  }

  .row.menu-header-bar {
    background: #fff;
    z-index: 10;
    width: 100%;
    max-width: $max-width-padded-fixed;
    margin-left: 0;
    margin-right: 0;
    .MuiTabs-fixed {
      overflow-x: scroll !important;
      .MuiTab-root.menu-tab {
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: $color-gray;
        padding: 0;
        min-width: auto;
        max-width: none;
        margin-right: 16px;
        padding: 4px 6px;
        min-width: unset;
        &:focus {
          user-select: none;
          outline: none;
        }
        &.MuiTab-textColorInherit.Mui-selected {
          color: $color-accent;
          opacity: 1;
        }
      }
    }
  }

  .header_fixed {
    height: 94px;
    width: 100%;
    background: white;
  }

  .menu-main-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 150px);
    position: relative;

    .menu-list {
      margin-bottom: 40vh;
    }

    .header_img_menu_list {
      height: 80px;
      overflow: hidden;
      width: 100%;
      padding: -13px;
      img {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
        object-position: center;
      }
    }

    .title_menu {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */
      letter-spacing: 0.1px;
      color: #000000;
    }

    .subsection-row {
      margin-left: 0;
      margin-right: 0;
    }

    .menu-item-card {
      margin-top: 12px;
      &:first {
        margin-top: 18px;
      }
    }

    .menu-item {
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 10px;
      .MuiParer-elevation1 {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      }
      .MuiCardContent-root {
        padding: 13px;
        &:last-child {
          padding: 13px;
        }
      }
    }
  }
}

.menu-item-header {
  font-family: "Nunito", sans-serif !important;
  font-weight: 700 !important;
  color: #000 !important;
  font-size: 16px;
  &.not-available {
    text-decoration: line-through;
  }
  &.price.not-available {
    color: red !important;
    font-size: 14px;
    text-decoration: none;
  }
}

.allergen-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.allergen-container {
  display: inline-block;
  margin-right: 10px;
  .allergen-icon {
    display: inline-block;
    margin-right: 10px;
  }
  .allergen-label {
    line-height: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }
}

.menu-item-detail-container {
  padding-bottom: 150px;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  overflow-y: scroll;
  .menu-item-sub-header {
    font-family: "Nunito", sans-serif !important;
    font-weight: 700 !important;
    color: #000 !important;
    font-size: 12px;
    text-transform: uppercase;
  }
  .menu-item-addons-price-label {
    color: #00a3f6;
    font-size: 12px;
    font-weight: 700;
  }
  .menu-item-supplements-container {
    padding: 15px;
    .menu-item-supplement-item {
      font-family: "Nunito", sans-serif;
      font-size: 12px;
      font-weight: 600;
      min-height: 30px;
      border-bottom: 1px solid #ddd;
      padding: 10px 0;
      line-height: 24px;
      cursor: pointer;
      div[class*="col"] {
        padding: 0 !important;
      }
    }
  }
  .menu-item-minus-row {
    margin-top: 15px;
    .menu-item-minus-item {
      width: auto;
      background: #000;
      color: #fff;
      border-radius: 20px;
      margin-right: 10px;
      padding: 5px 12px;
      display: inline-block;
      float: left;
      cursor: pointer;
      font-size: 1rem;
      &.selected {
        border: 1px solid #000;
        background: transparent;
        color: #000;
        text-decoration: line-through;
      }
    }
  }
  .carousel-item-container {
    padding: 0 !important;
    .CarouselItem {
      height: 216px;
      overflow: hidden;
      width: 100%;

      .carousel-image {
        width: 100%;
        height: 216px;
        display: block;
        margin: 0 auto;
        object-fit: cover;
      }
    }
  }
}

.sticky-footer {
  position: fixed;
  background: #FFFBFB;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 29;
  padding: 15px 25px 19px;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 10;
  max-width: 576px;
  margin: 0 auto;
  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: #888888;
  }
}

.btn.btn-download-app {
  background-color: #fa453b;
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  border-radius: 100px;
  color: #fff;
  box-shadow: 2px 2px 5px gray;
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    margin-right: 1em;
  }
  a {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 14px;    
    align-items: center;
    text-align: center;
    color: #FFFFFF;    
  }
}

.btn.btn-continue-web {
  background-color: white;
  margin: 0 auto;
  max-width: 360px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  border-radius: 100px;
  color: black;
  box-shadow: 2px 2px 5px gray;
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    margin-right: 1em;
  }
  a {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #454545;
  }
}

.bottom-fixed-row.menu-item-qty-row {
  height: 96px;
  padding: 4px 8px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .qty-container {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;

    .qty-action-quantity {
      text-align: right;
    }

    .qty-action-button {
      padding: 0 !important;
      width: 44px !important;
      height: 38px !important;

      /* float: left; */
      min-width: unset !important;
      max-width: 180px;
      width: 100%;
      user-select: none;
      outline: none;
      /* line-height: 24px; */
      display: inline-block;

      .qty-action-button-icon {
        border-radius: 24px !important;
        width: 24px;
        height: 24px;
        background: #000 !important;
      }

      .MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
      }

      &:focus {
        user-select: none;
        outline: none;
      }
    }

    .qty-label {
      /* height: 40px; */
      /* margin: 0 auto; */
      text-align: center;
      /* float: left; */
      font-family: "Nunito", sans-serif;
      font-size: 22px;
      font-weight: bold;
      /* line-height: 40px; */
      vertical-align: middle;
    }
  }

  .accent-button.qty-add-action-button {
    height: 44px;
    width: 100%;
    max-width: 165px;
  }
}

.add-on {
  margin-bottom: 16px;

  hr{
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .add-on-option{
    padding-left: 12px;
  }

  .qty-action-button {
    padding: 0 !important;
    width: 44px !important;
    height: 38px !important;
  
    /* float: left; */
    min-width: unset !important;
    max-width: 180px;
    width: 100%;
    user-select: none;
    outline: none;
    /* line-height: 24px; */
    display: inline-block;
  
    .qty-action-button-icon {
      border-radius: 24px !important;
      width: 24px;
      height: 24px;
      background: #FE3436 !important;
    }
  
    .MuiSvgIcon-root {
      width: 20px !important;
      height: 20px !important;
    }
  
    &:focus {
      user-select: none;
      outline: none;
    }
  }
  .vertically-center {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .add-on-header{
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .add-on-sub-header{
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color:#6c757d;
    
  }
  .option-header{
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 700;
  }
  .option-sub-header{
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
}