@media print {

  nav, 
  div.header {
      display: none;
  }
  div.app{
    height:100% !important;
  }

  div.screen.menu-quid-screen{
    div.container_header{
      display: none;
    }
    div.container_topbar{
      display: none;
    }

    div.sticky-footer{
      display: none;
    }

  }


  div.screen{
    height: 100% !important;
    overflow: visible !important;
  }

  div.menu-main-container{
    overflow: visible !important;
    height: 100% !important;
    position: relative;
  }

}
