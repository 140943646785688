.screen_location_service {
   
  overflow-y: scroll;
  max-height: 100%;

  .container-info-delivery{
    margin:0 auto;
    margin-top:px;
    width:100%;
    max-width: 400px;
    margin-top:20px;
    font-size: 14px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 10px;
    padding:16px;

    .label{
      font-weight: bold;
    }


  }

  .user_questions{
    margin-top: 40px;
  }

  
  .container-input-service_available{
    margin:0 auto;
    margin-top:55px;
    width:100%;
    max-width: 300px;
  }
  .container-suggestion-label-service_available{
    margin-bottom:8px;
    font-size: 12px;
    text-align: center;
    width: 100%;
  }

  .container-autocomplete-service_available{
    margin-top: -20px;
    background: white;
    z-index: 2;
    background: #FFFFFF;
    filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)), drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12)), drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
    max-width: 278px;
    width:100%;
    text-align: left;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14);
    display: none;
    &.open{
      display: block;
    }

    .autocomplete-dropdown{
      max-height:200px;
      overflow-y: scroll;
      overflow-x: hidden;

      .address-suggestion{
        font-family: 'Nunito';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #000000;
        cursor: pointer;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        padding: 8px 16px;
        &:hover{
          background: #FA453B;
          color: white;
        }
        &:last-child{
          border-bottom: 0px solid transparent;
        }
      }
    }
  }
}
